module.exports={
"label.account.name":`氏名（フリガナ`,
"label.account.copy":`メールに記載されているランダムな認証コードをコピーし、次のステップでパスワードを変更するためのリンクをクリックしてください。`,
"label.account.phone.length":`携帯電話番号は7文字以上必要です`,
"label.account.Start.trial":`ゲットストレイト`,
"label.account.employees1000":`従業員数1000人以上`,
"label.account.other.Ways":`その他のログイン方法`,
"label.account.emailsync.button.cancel":`キャンセル`,
"label.account.rule":`8～16桁の数字と文字で構成される。`,
"label.account.password.update.success":`パスワードの変更に成功しました。`,
"label.account.describe":`CloudCCは、デジタルパーソナライゼーション、分析インテリジェンス、グローバルコネクティビティに焦点を当て、企業のビジネス開発とともにオンデマンドでカスタマイズされます。`,
"label.account.enter.Emailbox":`メールアドレスを入力してください。`,
"label.account.password":`パスワード`,
"label.account.problem":`ログインに失敗しました。`,
"label.account.Complete.reset":`電子メールを送信します。パスワードの再設定を完了するために、認証コードを入力してください。`,
"label.account.crm.used":`上場企業で使われているCRM`,
"label.account.report.loading":``,
"label.account.notcustomer":`アカウントがない？`,
"label.account.employees501":`従業員数 501〜1000人`,
"label.account.title.operationManager":`オペレーションマネージャー`,
"label.account.Preset":`あらかじめ設定されたプロジェクト管理ツール`,
"label.account.company":`会社概要`,
"label.account.come":`Cloudccカスタマーサクセスプラットフォームへようこそ。私たちの新しいLightningプラットフォームは、最も速く、最も完全なソリューションを提供し、お客様を中心にすべてを行うことを可能にします。  `,
"label.account.input.password":`パスワードを入力してください。`,
"label.account.emailobject.emaildetail.button.back":`背面`,
"label.account.title.itManager":`ITマネージャー`,
"label.account.Modified.successfully":`パスワードの変更に成功しました。再度ログインをクリックし、ログイン画面に戻ってください。`,
"label.account.only11":`7桁以上入力してください。`,
"label.account.signin":`ログイン`,
"label.account.register":`登録して入手`,
"label.account.adopt":`検証合格!`,
"label.account.title.hrManager":`マーケティング/HRマネージャー`,
"label.account.input.usernames":`ユーザー名を入力してください。`,
"label.account.only.Numbers":`電話番号は数字のみ入力可能です。`,
"label.account.title.salesManager":`セールスマネージャー`,
"label.account.email":`電子メール`,
"label.account.click":`クリック`,
"label.account.and":`と`,
"label.account.mobile":`電話番号`,
"label.account.Privacy.policy":`プライバシーポリシー`,
"label.account.password.isnull":`パスワードは空白にできません。`,
"label.account.employees101":`従業員数101〜500名`,
"label.account.remember.name":`ユーザー名を記憶する`,
"label.account.Select.country":`国・地域を選択してください。`,
"label.account.distinguish":`パスワードは大文字と小文字を区別します。`,
"label.account.fillMobile":`電話番号を入力してください。`,
"label.account.Preconfigured":`あらかじめ設定されたレポートとダッシュボード`,
"label.account.Select.job":`ポジションを選択してください。`,
"label.account.employees21":`従業員数21〜100名`,
"label.account.username":`ユーザー名`,
"label.account.Submit":`提出`,
"label.account.confirm":`アカウント確認`,
"label.account.contact":`お問い合わせ`,
"label.account.country":`国名`,
"label.account.username.isnull":`ユーザー名を空にすることはできません。`,
"label.account.forget.password":`パスワードを忘れた方はこちら`,
"label.account.Loading":`ローディング`,
"label.account.goon":`継続`,
"label.account.Register.trial":`無料トライアルを申し込む`,
"label.account.title.other":`その他`,
"label.account.surname":`苗字`,
"label.account.Fail":`検証に失敗しました。もう一度やり直してください。`,
"label.account.Terms.service":`サービス規約`,
"label.account.Select.staff":`従業員数を選択してください。`,
"label.account.username.enter":`苗字を入力してください。`,
"label.account.trial":`無料トライアル`,
"label.account.employees1":`従業員数1～20名`,
"label.account.Quick.registration":`または素早くアクセスすることができます。`,
"label.account.tabpage.ok":`コンファーム`,
"label.account.normal.reregister":`再ログイン`,
"label.account.batchadd.newbatchpage.save.em":`電子メールの形式が正しくありません。`,
"label.account..user.title":`役職名`,
"label.account.No.credit.card.required":`クレジットカードは不要です。`,
"label.account.enterprise.Wechat":`ウィコム`,
"label.account.sample.data":`サンプルデータのプリロード`,
"label.account.title.hobbies":`趣味`,
"label.account.platform":`インテリジェントなモバイル・デジタルCRMの新プラットフォーム`,
"label.account.sent":`メール送信`,
"label.account.Confirm.password":`パスワードを確認してください。`,
"label.account.fill":`すべてご記入ください。`,
"label.account.user.resetpw":`パスワードの再設定`,
"label.account.Spell":`スライダーをドラッグして、パズルを完成させます。`,
"label.account.identifying.code":`認証コードを入力してください。`,
"label.account.staff":`従業員数`,
"label.account.welcome":`CloudCCへようこそ`,
"label.account.online.guidance":`オンラインコンサルティングとインストラクション`,
"label.account.login.CloudCC":`CloudCCへのログイン`,
"label.account.Company.name":`会社名を入力してください。`,
"label.account.title.generalManager":`ゼネラルマネージャー`,
"label.account.enter.user":`パスワードをリセットするには、CloudCCのユーザー名を入力してください。`,
"label.account.sent.code":`検証コードが送信されました`,
"label.account.pw.error.text":`入力された2つのパスワードが一致しませんでした。`,
"label.account.title.customerManager":`カスタマー・サービス・マネージャー`,
"label.account.label.captcha":`検証コード`,
"label.account.Trial.days":`30日間の無料トライアルを申し込む`,
"label.account.please.enter.name":`お名前を入力してください。`,
"label.account.inspect":`認証コード確認メールが届かない場合は、迷惑メールをご確認ください。`,
"label.account.User.rule":`ユーザー名はメールアドレスの形式をとっています。`,
"label.account.go.Login":`すでにアカウントをお持ちの方`,
"label.account.Reading.consent":`に同意します。`,
"label.account.Change.Password2":`パスワードの変更`,
"label.account.Loginby":`ログインビー`,
"label.account.apply.partner":`CloudCCパートナーに応募する`,
"label.account.MobileLogin":`モバイル`,
"label.account.Registration":``,
"label.account.Italy":`イタリア`,
"label.account.view.details":`詳細を見る`,
"label.account.accept":`利用規約とプライバシーポリシーに同意してください。`,
"label.account.China.Mainland":`中国本土`,
"label.account.NewPassword":`新しいパスワードを入力してください。`,
"label.account.Mobile.not.empty":`携帯電話番号を空にすることはできません`,
"label.account.Please.select":`選択してください`,
"label.account.market.introduce":`マーケティングチャネルの統一プラットフォームで、より多くのリード獲得に貢献`,
"label.account.IOS":`スキャンしてiOSアプリをダウンロード`,
"label.account.failsend":`送信に失敗しました`,
"label.account.UpperLowerlAphanumeric.character":`大文字、小文字、数字、記号が必要です。`,
"label.account.New.Password1":`新しいパスワード`,
"label.account.succSend":`電子メールが送信されました。メールに記載されている認証コードをコピーして、パスワードを再設定してください。`,
"label.account.Account.mobile":`口座番号または携帯電話番号`,
"label.account.Login.consent":`ログインすることにより、以下の事項に同意したものとみなされます。`,
"label.account.fillAreaCode":`市外局番を入力してください`,
"label.account.active.login":``,
"label.account.jp":`日本`,
"label.account.partner.success1":`CloudCCパートナー登録が完了しました。`,
"label.account.Reset":`リセット`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`旧パスワードを入力してください`,
"label.account.Germany":`ドイツ`,
"label.account.title.content":`CloudCCに登録する｜30日間無料トライアル  今すぐお試しください。`,
"label.account.Taiwan":`台湾、中国`,
"label.account.China.Station":`中国`,
"label.account.sale.introduce":`セールスオートメーションは、より多くの案件を獲得するのに役立ちます。`,
"label.account.Back":`前のステップ`,
"label.account.market":`マーケティングクラウド`,
"label.account.section.company":`会社概要`,
"label.account.check.email":`しばらくしてから、担当者よりご連絡いたしますので、メールをご確認ください。`,
"label.account.characters":`5文字`,
"label.account.International":`インターナショナル`,
"label.account.britain":`イギリス`,
"label.account.reg.fail.msg":`サインアップに失敗しました。後でもう一度お試しください。`,
"label.account.success.reg":`ご登録ありがとうございました。`,
"label.account.company.url":`ウェブサイト`,
"label.account.CannotBeEmpty":`空であってはならない`,
"label.account.phone.note":``,
"label.account.second":`{0}秒後`,
"label.account.Change.Password":`パスワードの変更`,
"label.account.Netherlands":`オランダ`,
"label.account.pleaseAgree":`利用規約とプライバシーポリシーに同意してください。`,
"label.account.copyright":`株式会社クラウドシーシーAll Rights Reserved`,
"label.account.letter":`1通`,
"label.account.remember":`今思い出した`,
"label.account.accountNum":`アカウント`,
"label.account.code.not.empty":`キャプチャを空にすることはできません`,
"label.account.Spain":`スペイン`,
"label.account.Alphanumeric":`アルファベットと数字が混在していることが必要`,
"label.account.obtain":`アクワイア`,
"label.account.company.txt":`事業体として登録されていない場合は、名前を追加してください`,
"label.account.con.browser":``,
"label.account.Android":`スキャンしてAndroidアプリをダウンロード`,
"label.account.title":`CloudCC 無料トライアル  CloudCC.com`,
"label.account.partner.success2":`しばらくしたら、私たちから連絡します。`,
"label.account.Macao":`中国・マカオ`,
"label.account.Brazil":`ブラジル`,
"label.account.service.introduce":`シームレスなサービスプロセスで対応効果を高める`,
"label.account.codeFailure":`無効な認証コード`,
"label.account.AccountLogin":`アカウント`,
"label.account.pswComplexReqRules":`パスワードの最小の長さは{0}で、{1}です。`,
"label.account.UpperLowerlAphanumeric":`大文字、小文字、数字が必要です。`,
"label.account.active.username":``,
"label.account.rememberPassword":`パスワードの記憶`,
"label.account.HongKong":`中国・香港`,
"label.account.oldPass":`旧パスワード`,
"label.account.Data.storage":`お客様のデータは{0}データセンターに保存されます。`,
"label.account.number":`1番号`,
"label.account.Poland":`ポーランド`,
"label.account.service":`サービスクラウド`,
"label.account.Incorrect.format":`フォーマットまたは番号が正しくありません。`,
"label.account.remember.status":`リメンバー・ミー`,
"label.account.sale":`セールスクラウド`,
"label.account.No.account.binding":`アカウントがバインドされていません。管理者に連絡してください`,
"label.account.Russia":`ロシア`,
"label.account.France":`フランス`,
"label.account.partner.content":`CloudCCは、業界を超えたソリューションを模索するグローバル企業として、現在パートナーを募集しています。ぜひ、当社のアフィリエイトプログラムにご参加ください。`,
"label.account.reg.phone.already":`この電話機には、この組織がすでに存在しています。ログインするか、別の電話機で登録してください。`,
"label.account.Confirm":`新しいパスワードの確認`,
"label.account.VerificationLogin":`ベリファイコード`,
"label.account.USA":`米国`,
"label.account.mobile.num":`携帯電話番号を入力してください`,
"label.account.MinLength":`パスワードの長さの最小値は`,
"label.account.change.your.password":`セキュリティの観点から、今すぐパスワードの変更をお願いします`,
"label.account.new.password":`0}の新しいパスワードを入力してください。少なくとも含めるようにしてください。`,
"label.account.website.enter":`オフィシャルサイトのアドレスを入力してください`,
"label.account.pswComplexIsZero":``
}